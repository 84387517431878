<script>
import { global } from './utils'
import { EventBus } from '@/event-bus'

import {
  BIconSearch,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
} from 'bootstrap-vue'

export default {
  name: 'Search',
  props: {},
  components: {
    BIconSearch,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
  },
  data() {
    return {
      global,
      searchTerm: '',
    }
  },
  methods: {
    clearSearchTerm() {
      this.searchTerm = ''
    },
    broadcastSearchTerm() {
      EventBus.$emit('event-search-term', this.searchTerm)
    },
  },
  computed: {
    isIndexPage() {
      return window.location.pathname === '/'
    },
  },
  mounted() {},
  created() {
    EventBus.$on('event-search-term-clear', this.clearSearchTerm)
  },
}
</script>

<style lang="scss"></style>

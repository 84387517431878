export const global = {
  getCdnImage(path, width, height) {
    return (
      process.env.VUE_APP_IMAGE_CDN_URL +
      '/agitator-image-host/grapevine/' +
      'tr:w-' +
      width +
      ',h-' +
      height +
      '/' +
      path
    )
  },
  getKarolinafundCdnImage(path, width, height) {
    return (
      process.env.VUE_APP_IMAGE_CDN_URL +
      '/' +
      'tr:w-' +
      width +
      ',h-' +
      height +
      '/' +
      path.replace('project_content_pics', 'project_pics')
    )
  },
  debug(message) {
    // eslint-disable-next-line no-console
    console.log(message)
  },
  getDaysDifference(start, end) {
    const timeDifference = end - start
    const daysDifference = timeDifference / (1000 * 3600 * 24)

    return parseInt(daysDifference)
  },
  getHoursDifference(start, end) {
    const timeDifference = end - start
    const hoursDifference = timeDifference / (1000 * 3600)

    return parseInt(hoursDifference)
  },
}
